<template>
  <section class="lesiurpackages_mobail" v-if="filteredDeal && filteredDeal.length">
    <h2 class="text-center offers-title">{{ secondBannerHeaderHome }}</h2>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <Agile :options="myOptions" class="owl-carousel owl-theme feathers-slider theme-slider ltr" ref="agile">
            <div class="everiting_box" v-for="(slide, index) in filteredDeal" :key="index" ref="everitingBox">
              <LesiurPackageItem :item="slide"/>
            </div>
          </Agile>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import getBannerImage from '../../../mixins/getBannerImage';

export default {
  name: 'Lesiurpackages',
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
    LesiurPackageItem: () => import('@/components/home/LesiurpackageItem/LesiurpackageItemTheme2'),
  },
  mixins: [getBannerImage],
  props: {
    promotionData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slide: 0,
      myOptions: {
        autoplay: true,
        infinite: true,
        navButtons: false,
        slidesToShow: 1,
        autoplaySpeed: 4000,
        rtl: true,
        dots: false,
        speed: 4000,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 440,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  computed: {
    filteredDeal() {
      return this.promotionData.filter((deal) => deal.promotion === true);
    },
  },
  mounted() {
    if (/* @cc_on!@ */false || !!document.documentMode) {
      setTimeout(() => {
        this.$refs.agile.getWidth();
      }, 1000);
    }
  },
};
</script>
<style scoped>
.offers-title {
  font-family: "ploni";
  font-weight: bold;
  text-transform: capitalize;
}
.everiting_box {
  padding: 0px;
}
@media (max-width: 767px) {
  .offers-title  {
    font-size: 1.5rem;
  }
  .lesiurpackages_mobail .owl-carousel.theme-slider {
    padding: 0;
    margin: auto;
  }
}
</style>
